<template>
  <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
    <base-widget
      label="Total Harga Stok Tersedia"
      :value="summary.total_price_stock_available"
    />
    <base-widget
      label="Total PV Tersedia"
      :value="summary.total_point_stock_available"
    />
    <base-widget
      label="Total BV Tersedia"
      :value="summary.total_bonus_stock_available"
    />
  </div>
</template>

<script>
import BaseWidget from '@/components/base/BaseWidget.vue';
import { mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';

export default {
  components: { BaseWidget },
  mixins: [requestMixin],
  data() {
    return {
      summary: {
        total_price_stock_available: 0,
        total_point_stock_available: 0,
        total_bonus_stock_available: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      getTotalStockAvailable: 'stocks/getTotalStockAvailable',
      me: 'auth/getUser',
    }),
  },
  methods: {
    async loadSummary() {
      const [res, err] = await this.request('/api/v1/stocks/-actions/total-stock-available', {
        params: {
          'filter[warehouse_uuid]': this.me.current_warehouse
        }
      })

      if (!err) {
        this.summary = res
      }
    }
  },
  created() {
    this.loadSummary()
  },
};
</script>
